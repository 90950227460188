import React, { useEffect } from 'react';
import logo from './images/default-img.png';
import {Link, BrowserRouter, Route, Routes, useParams } from 'react-router-dom';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { doc, getDoc, getDocs, collection, query, where, setDoc, deleteDoc } from "firebase/firestore";
import { Card, CardContent, TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, Select, MenuItem, LinearProgress, InputLabel, FormControl } from "@mui/material";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export default class AdminPage extends React.Component {
    constructor(props) {
        super(props);

        var firebaseConfig = {
            apiKey: "AIzaSyByx_HtrqJm-cI8F1c6kYI9arYHFiLdDbA",
            authDomain: "buzz-studios-7f814.firebaseapp.com",
            databaseURL: "https://buzz-studios-7f814-default-rtdb.firebaseio.com",
            projectId: "buzz-studios-7f814",
            storageBucket: "buzz-studios-7f814.appspot.com",
            messagingSenderId: "103909984010",
            appId: "1:103909984010:web:6b15701bb800c731cd1cf3",
            measurementId: "G-GY05FJ1M7L"
        };

        this.state = {
            firebaseConfig: firebaseConfig
        };
    }
    
    
    componentDidMount() {
        const app = initializeApp(this.state.firebaseConfig);
        const auth = getAuth(app);
        this.setState({App: app});
        this.setState({Auth: auth.currentUser});
        var already = false;

        if (isSignInWithEmailLink(auth, window.location.href)) {

        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
            //email = window.prompt('Please provide your email for confirmation');
        }

        signInWithEmailLink(auth, email, window.location.href)
            .then((result) => {
                window.localStorage.removeItem('emailForSignIn');
                this.RetrieveFilms(auth.currentUser.email);
                this.setState({Email: auth.currentUser.email});
            })
            .catch((error) => {
                if (!already) {
                    already = true;
                    this.setState({errorDialog: true})
                }
            });
        }
    }   

    RetrieveFilms(user) {

        const fetch = async () => {
            var db = getFirestore();
            console.log(user);

            var getStatus = query(collection(db, "users"), where("email", "==", user));
            var status = await getDocs(getStatus);
            var exec = false;
            status.forEach((doc) => {
                var info = doc.data();
                if (info.exec) {
                    this.setState({Exec: true});
                    exec = true;
                } else {
                    this.setState({Exec: false});
                }
                this.setState({User: user});
            });


            var filmArray = [];
            if (exec) {
                var docRef = collection(db, "films");
            } else {
                var docRef = query(collection(db, "films"), where("authorized", "array-contains", user));
            }
            var films = await getDocs(docRef);
            films.forEach((doc) => {
                var film = doc.data();
                film.id = doc.id;
                filmArray.push(film);
            });
            this.setState({Films: filmArray});

            var requestArray = [];
            if (exec) {
                var reqRef = query(collection(db, "requests"), where("status", "==", "open"));
            }
            var requests = await getDocs(reqRef);
            requests.forEach((doc) => {
                var request = doc.data();
                request.id = doc.id;
                requestArray.push(request);
            });
            this.setState({Requests: requestArray});

            // docRef = doc(db, "films", "theblame");
            // docSnap = await getDoc(docRef);
            // console.log(docSnap.data);
        }

        fetch();
    }

    render() {
        return (
            <>
                <style>
                    {`
                        body {
                            background-color: #704300cf;
                        }
                    `}
                </style>
                <BuzzHeader/>
                <Dialog
                    open={this.state.errorDialog}
                    onClose={() => window.close()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle style={{backgroundColor: "#222222"}} id="alert-dialog-title">
                        Authentication Failed
                    </DialogTitle>
                    <DialogContent style={{backgroundColor: "#222222", width: 400}}>
                    <DialogContentText id="alert-dialog-description" style={{color: "white", textAlign: "center"}}>
                        Your session may have expired, or you have used an invalid URL. Please request a new login link.
                    </DialogContentText>
                    </DialogContent>
                </Dialog>
                <AdminControls Exec={this.state.Exec} Films={this.state.Films} User={this.state.User} Requests={this.state.Requests} Refresh={() => this.RetrieveFilms(this.state.Email)}/>
            </>
        );
    }
};

const BuzzHeader = () => {
    return (
        <div className="header">
            <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
            <Link to="/">
                <img src={logo} alt="Buzz Studios Filmmaking Club" />
            </Link>
            </div>
        </div>
    )
};

const AdminControls = (props) => {

    const [open, setOpen] = React.useState();
    const [filmExists, setFilmExists] = React.useState();
    const [thumbnailExists, setThumbnailExists] = React.useState();
    const [scriptExists, setScriptExists] = React.useState();
    const [captionsExist, setCaptionsExist] = React.useState();
    const [selectedFilm, setSelectedFilm] = React.useState();
    const [stage, setStage] = React.useState();
    const [progress, setProgress] = React.useState();

    const [newFilm, setNew] = React.useState();
    const [newFilmID, setNewFilmID] = React.useState();
    const [request, setRequest] = React.useState();

    const [newTitle, setNewTitle] = React.useState();
    const [newSemester, setNewSemester] = React.useState();
    const [newDirector, setNewDirector] = React.useState();
    const [newDirectorEmail, setNewDirectorEmail] = React.useState();
    const [newAuthorized, setAuthorized] = React.useState();
    const [newStars, setNewStars] = React.useState();
    const [newSynopsis, setNewSynopsis] = React.useState();
    const [access, setAccess] = React.useState();
    const [accessCode, setAccessCode] = React.useState();
    const [newOrder, setNewOrder] = React.useState();
    const [newIndep, setNewIndep] = React.useState();

    const [filmFileUpdated, setFilmFileUpdated] = React.useState();
    const [thumbnailUpdated, setThumbnailUpdated] = React.useState();
    const [scriptUpdated, setScriptUpdated] = React.useState();
    const [captionsUpdated, setCaptionsUpdated] = React.useState();
    const [filmFileName, setFilmFileName] = React.useState();
    const [thumbnailName, setThumbnailName] = React.useState();
    const [scriptName, setScriptName] = React.useState();
    const [captionsName, setCaptionsName] = React.useState();
    const [filmOverride, setFilmOverride] = React.useState();
    const [thumbOverride, setThumbOverride] = React.useState();
    const [scriptOverride, setScriptOverride] = React.useState();
    const [captionsOverride, setCaptionsOverride] = React.useState();
    
    const [viewableFilm, setViewableFilm] = React.useState();
    const [viewableThumbnail, setViewableThumbnail] = React.useState();
    const [viewableScript, setViewableScript] = React.useState();
    const [viewableCaptions, setViewableCaptions] = React.useState();

    const [selectedUser, setSelectedUser] = React.useState();

    useEffect(() => {
        setStage(0);
        setProgress(0);
        console.log(props.Films);
        setFilmFileUpdated(false);
        setThumbnailUpdated(false);
        setScriptUpdated(false);
        setCaptionsUpdated(false);
        setAuthorized([]);
        setRequest(false);
        setAccess("released");
        console.log(props.User);
    }, []);

    useEffect(() => {
        if (progress == 100) {
            setStage(stage + 1);
            setOpen(false);
            setProgress(0);
        }
    }, [progress]);

    useEffect(() => {
        console.log(newAuthorized);
    }, [newAuthorized]);

    useEffect(() => {
        const storage = getStorage();
        const storageFile = ref(storage, "gs://buzz-studios-7f814.appspot.com/" + selectedFilm + ".mp4")

        getDownloadURL(storageFile)
        .then(url => {
            setFilmExists(true);
        })
        .catch(error => {
            setFilmExists(false);
        });


        const thumbnailFile = ref(storage, "gs://buzz-studios-7f814.appspot.com/" + selectedFilm + ".png")

        getDownloadURL(thumbnailFile)
        .then(url => {
            setThumbnailExists(true);
        })
        .catch(error => {
            setThumbnailExists(false);
        });

        const scriptFile = ref(storage, "gs://buzz-studios-7f814.appspot.com/" + selectedFilm + ".pdf")

        getDownloadURL(scriptFile)
        .then(url => {
            setScriptExists(true);
        })
        .catch(error => {
            setScriptExists(false);
        });

        const captionsFile = ref(storage, "gs://buzz-studios-7f814.appspot.com/" + selectedFilm + ".vtt")
        
        getDownloadURL(captionsFile)
        .then(url => {
            setCaptionsExist(true);
        })
        .catch(error => {
            setCaptionsExist(false);
        })

    }, [selectedFilm])

    const importFile = (type) => {
        let input = document.createElement('input');
        
        if (type == "video") {
            input.accept = ".mp4";
        } else if (type == "image") {
            input.accept = ".png";
        } else if (type == "document") {
            input.accept = ".pdf";
        } else if (type == "captions") {
            input.accept = ".vtt";
        }

        var file = null;
        input.type = 'file';
        input.onchange = _ => {
            let files =   Array.from(input.files);
            file = files[0];
            console.log(files);
            handleOpen();

            const storage = getStorage();
            if (type === "video") {
                const metadata = {
                    contentType: 'video/mp4'
                };

                var today = new Date();
                var fileName = selectedFilm + "-" + String(today.getTime()) + ".mp4";
                setFilmFileName(fileName);
                const storageRef = ref(storage, fileName);
                const uploadTask = uploadBytesResumable(storageRef, file, metadata);

                uploadTask.on('state_changed',
                    (snapshot) => {
                        setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    }, 
                    (error) => {
                        alert("An error has occurred. Please try again.");
                    }, 
                    () => {
                        console.log("Upload complete");
                        setFilmFileUpdated(true);
                    }
                );
            } else if (type === "image") {
                const metadata = {
                    contentType: 'image/png'
                };

                var today = new Date();
                var fileName = selectedFilm + "-thumbnail-" + String(today.getTime()) + ".png";
                const storageRef = ref(storage, "gs://buzz-studios-7f814.appspot.com/" + fileName);
                setThumbnailName(fileName);
                const uploadTask = uploadBytesResumable(storageRef, file, metadata);

                uploadTask.on('state_changed',
                    (snapshot) => {
                        setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    }, 
                    (error) => {
                        alert("Upload failed. Please try again later.");
                    }, 
                    () => {
                        console.log("Upload complete");
                        setThumbnailUpdated(true);
                    }
                );
            } else if (type === "document") {
                const metadata = {
                    contentType: 'application/pdf'
                };

                var today = new Date();
                var fileName = selectedFilm + "-script-" + String(today.getTime()) + ".pdf";
                const storageRef = ref(storage, "gs://buzz-studios-7f814.appspot.com/" + fileName);
                setScriptName(fileName);
                const uploadTask = uploadBytesResumable(storageRef, file, metadata);

                uploadTask.on('state_changed',
                    (snapshot) => {
                        setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    },
                    (error) => {
                        alert("Upload failed. Please try again later.");
                    },
                    () => {
                        console.log("Upload complete");
                        setScriptUpdated(true);
                    }
                );
            } else if (type == "captions") {
                const metadata = {
                    contentType: 'text/vtt'
                };

                var today = new Date();
                var fileName = selectedFilm + "-captions-" + String(today.getTime()) + ".vtt";
                const storageRef = ref(storage, "gs://buzz-studios-7f814.appspot.com/" + fileName);
                setCaptionsName(fileName);
                const uploadTask = uploadBytesResumable(storageRef, file, metadata);

                uploadTask.on('state_changed',
                    (snapshot) => {
                        setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    },
                    (error) => {
                        console.log(error);
                        alert("Upload failed. Please try again later.");
                    },
                    () => {
                        console.log("Upload complete");
                        setCaptionsUpdated(true);
                    }
                );
            }
        };
        input.click();
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getFilm = (id) => {
        if (props.Films !== undefined) {
            var film = null;
            for (var i = 0; i < props.Films.length; i++) {
                if (props.Films[i].id === id) {
                    film = props.Films[i];
                    return film;
                }
            }
            if (film == null) {
                return;
            }
        }
    }

    const getRequest = (id) => {
        if (props.Requests !== undefined) {
            var req = null;
            for (var i = 0; i < props.Requests.length; i++) {
                if (props.Requests[i].id === id) {
                    req = props.Requests[i];
                    return req;
                }
            }
            if (req == null) {
                return;
            }
        }
    }


    const handleChange = (event) => {
        setSelectedFilm(event.target.value);
        var film = getFilm(event.target.value);
        set(film);
    };

    const handleRequest = (event) => {
        setSelectedFilm(event.target.value);
        var film = getRequest(event.target.value);
        set(film);
        getURL(getRequest(event.target.value).filmfile, "film");
        getURL(getRequest(event.target.value).thumbnail, "thumbnail");
        getURL(getRequest(event.target.value).script, "script");
        getURL(getRequest(event.target.value).captions, "captions");
    };

    const set = (film) => {
        setNewTitle(film.title);
        setNewSemester(film.semester);
        setNewDirector(film.director);
        setAuthorized(film.authorized);
        setNewStars(film.stars);
        setNewIndep(film.independent !== undefined ? film.independent : false);
        setNewSynopsis(film.synopsis);
        setAccess(film.access);
        setFilmFileUpdated(false);
        setThumbnailUpdated(false);
        setFilmFileName(film.filmfile !== undefined ? film.filmfile : "");
        setThumbnailName(film.thumbnail !== undefined ? film.thumbnail : "");
        setScriptName(film.script !== undefined ? film.script : "");
        setCaptionsName(film.captions !== undefined ? film.captions : "");
        setNewOrder(film.order);
    };

    const sendRequest = () => {
        if (props.User === undefined) {
            alert("Please re-authenticate.");
        } else {
            const send = async () => {
                var db = getFirestore();
                var today = new Date();

                const t = require("bcryptjs");
                var salt = t.genSaltSync(10);
                if (accessCode !== undefined) {
                    var hash = t.hashSync(accessCode, salt);
                } else {
                    var hash = t.hashSync("", salt);
                }

                if (newFilm || props.Exec) {
                    await setDoc(doc(db, "films", request ? getRequest(selectedFilm).film : selectedFilm), {
                        title: newTitle,
                        semester: newSemester,
                        director: newDirector,
                        authorized: newAuthorized,
                        stars: newStars,
                        independent: newIndep,
                        synopsis: newSynopsis,
                        order: newOrder,
                        access: access,
                        accesscode: hash,
                        filmfile: filmFileName,
                        thumbnail: thumbnailName,
                        script: scriptName,
                        captions: captionsName
                    });
                } else {
                    await setDoc(doc(db, "requests", "request-" + String(today.getTime())), {
                        film: selectedFilm,
                        user: props.User,
                        title: newTitle,
                        semester: newSemester,
                        director: newDirector,
                        authorized: newAuthorized,
                        stars: newStars,
                        independent: newIndep,
                        synopsis: newSynopsis,
                        order: newOrder,
                        access: access,
                        accesscode: hash,
                        filmfile: filmFileName,
                        thumbnail: thumbnailName,
                        script: scriptName,
                        captions: captionsName,
                        status: "open"
                    });
                }
                setStage(7);
            }
            send();
        }
    };

    const getURL = (id, type) => {
        const storage = getStorage();
        const storageFile = ref(storage, "gs://buzz-studios-7f814.appspot.com/" + id);
        console.log(storageFile);

        getDownloadURL(storageFile)
        .then(url => {
            if (type === "film") {
                setViewableFilm(url);
            } else if (type === "thumbnail") {
                setViewableThumbnail(url);
            } else if (type === "script") {
                setViewableScript(url);
            } else if (type === "captions") {
                setViewableCaptions(url);
            }
        })
        .catch(error => {
            console.log("URL failed.");
        });
    };

    const confirmRequest = (approved) => {
        if (approved) {
            sendRequest();
        }
        const send = async () => {
            var db = getFirestore();
            await setDoc(doc(db, "requests", selectedFilm), {
                status: "closed"
            });
            setStage(5);
        }
        send();
    };

    const deleteFilm = () => {
        var db = getFirestore();
        const del = async () => {
            await deleteDoc(doc(db, "films", selectedFilm));
        }
        del();
    }

    const clear = () => {
        setNewTitle("");
        setNewSemester("");
        setNewDirector("");
        setAuthorized([]);
        setNewStars("");
        setNewSynopsis("");
        setNewDirectorEmail("");
        setAccess("");
        setAccessCode("");
        setNewIndep(false);
        setFilmFileUpdated(false);
        setThumbnailUpdated(false);
        setScriptUpdated(false);
        setCaptionsUpdated(false);
        setFilmFileName("");
        setThumbnailName("");
        setScriptName("");
        setCaptionsName("");
        setNewOrder("");
    }

    const reset = () => {
        clear();
        setStage(0);
        setFilmFileUpdated(false);
        setThumbnailUpdated(false);
        setScriptUpdated(false);
        setCaptionsUpdated(false);
        setAuthorized([]);
        setRequest(false);
        setProgress(0);
        setNewDirectorEmail(null);
        setNewFilmID(null);
        setSelectedFilm(null);
        setFilmOverride(null);
        setThumbOverride(null);
        setScriptOverride(null);
        setCaptionsOverride(null);
        setNewOrder(null);
        props.Refresh();
    }

    return (
        <>
        <style>
            {`
                h2 {
                    grid-column: 1 / -1;
                    margin-top: 40px;
                    font-size: 30px;
                    text-align: center;
                    color: white;
                }
            `}
        </style>
        <h2>Submit Film Details</h2>
        <div>
            {stage === 0 && <Card variant="outlined" sx={{width: 500, margin: "0 auto"}}>
                <CardContent style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                    {props.Exec && 
                    <>
                    <p style={{color: "black", fontSize: 25, margin: 10, fontFamily: "Lucida Sans"}}>Add New Film</p>
                    <TextField value={newFilmID} onChange={(event) => {setNewFilmID(event.target.value); setNew(true);}} id="outlined-basic" label="Film ID" variant="outlined" sx={{margin: 1}} /> 
                    <Button onClick={() => {if (newFilmID !== undefined) {setSelectedFilm(newFilmID); clear(); setStage(1);}}} variant="contained" color="warning" style={{fontSize: 20, marginTop: 10}}>Add Details</Button>
                    <br></br>
                    </>
                    }
                    
                    <p style={{color: "black", fontSize: 25, margin: 10, fontFamily: "Lucida Sans"}}>{props.Exec ? "Existing Films" : "My Films"}</p>
                    <FormControl style={{margin: "0 auto"}}>
                        <InputLabel id="demo-simple-select-label">{props.Exec ? "Existing Films" : "My Films"}</InputLabel>
                        <Select
                            labelId="select-existing-film"
                            id="select-film-id"
                            value={selectedFilm}
                            label={props.Exec ? "Existing Films" : "My Films"}
                            onChange={handleChange}>
                            {
                                props.Films !== undefined && props.Films.map(film => (
                                    <MenuItem value={film.id}>{film.id}</MenuItem>
                                ))
                            }
                        </Select>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                            <Button onClick={() => {if (selectedFilm !== undefined) {setStage(1)}}} variant="contained" color="warning" style={{fontSize: 20, margin: 5}}>Edit Details</Button>
                            {props.Exec && <Button onClick={() => {if (selectedFilm !== undefined) {deleteFilm(); setStage(5);}}} variant="contained" color="warning" style={{fontSize: 20, margin: 5, backgroundColor: "maroon"}}>Delete Film</Button>}
                        </div>
                    </FormControl>

                    {props.Exec &&
                    <>
                    <br></br>
                    <p style={{color: "black", fontSize: 25, margin: 10, fontFamily: "Lucida Sans"}}>Open Requests</p>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Open Requests</InputLabel>
                        <Select
                            labelId="select-request"
                            id="select-request-id"
                            value={selectedFilm}
                            style={{width: 200, margin: "0 auto"}}
                            label={"Open Requests"}
                            onChange={handleRequest}>
                            {
                                props.Requests !== undefined && props.Requests.map(request => (
                                    <MenuItem value={request.id}>{request.film}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <Button onClick={() => {if (selectedFilm !== undefined) {setRequest(true); setStage(1)}}} variant="contained" color="warning" style={{fontSize: 20, marginTop: 10}}>Review Request</Button> 
                    
                    </>
                    }
                </CardContent>
            </Card>}
            {stage === 1 && <Card variant="outlined" sx={{width: 500, margin: "0 auto"}}>
                <CardContent style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                    <p style={{color: "black", fontSize: 30, marginTop: 0}}>Film ID: <strong>{request ? getRequest(selectedFilm).film : selectedFilm}</strong></p>
                    <TextField value={newTitle} onChange={(event) => setNewTitle(event.target.value)} id="outlined-basic" label="Film Title" variant="outlined" sx={{width: 400, margin: 1}} />
                    {props.Exec && <TextField value={newSemester} onChange={(event) => setNewSemester(event.target.value)} id="outlined-basic" label="Semester Produced" variant="outlined" sx={{width: 400, margin: 1}} />}
                    <TextField value={newDirector} onChange={(event) => setNewDirector(event.target.value)} id="outlined-basic" label="Director's Name" variant="outlined" sx={{width: 400, margin: 1}} />
                    {props.Exec && 
                        <>
                        <br></br>
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">Authorized Users</InputLabel>
                            <Select
                                labelId="authorized"
                                id="authorized-id"
                                value={selectedUser}
                                style={{width: 400, margin: "0 auto"}}
                                label={"Authorized Users"}
                                onChange={(event) => setSelectedUser(event.target.value)}>
                                {
                                    newAuthorized !== undefined && newAuthorized.map(auth => (
                                        <MenuItem value={auth}>{auth}</MenuItem>
                                    ))    
                                }
                            </Select>
                        </FormControl>
                        <TextField value={newDirectorEmail} onChange={(event) => setNewDirectorEmail(event.target.value)} id="outlined-basic" label="" variant="outlined" sx={{width: 400, margin: 1}} />
                        
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                            <Button onClick={() => setAuthorized(oldArray => [...oldArray, newDirectorEmail])} variant="contained" color="warning" style={{fontSize: 10, margin: 5}}>Add</Button>
                            <Button onClick={() => setAuthorized(newAuthorized.filter(item => item !== selectedUser))} variant="contained" color="warning" style={{fontSize: 10, margin: 5}}>Remove</Button>
                        </div>
                        <br></br>
                        </>
                    }
                    <TextField value={newStars} onChange={(event) => setNewStars(event.target.value)} id="outlined-basic" label="Stars" variant="outlined" sx={{width: 400, margin: 1}} />
                    <TextField value={newSynopsis} onChange={(event) => setNewSynopsis(event.target.value)} id="outlined-basic" label="Synopsis" variant="outlined" multiline maxRows={8} sx={{width: 400, margin: 1}} />
                    {props.Exec && <TextField value={newOrder} onChange={(event) => setNewOrder(parseInt(event.target.value) !== NaN ? parseInt(event.target.value) : 0)} id="outlined-basic" label="Order" variant="outlined" multiline maxRows={8} sx={{width: 400, margin: 1}} />}
                    
                    <FormControl style={{width: 400, margin: "0 auto"}}>
                    <InputLabel id="demo-simple-select-label">Category</InputLabel>
                    <Select
                        labelId="select-independent"
                        id="select-independent-id"
                        value={newIndep}
                        style={{width: 400, margin: "0 auto"}}
                        onChange={(event) => setNewIndep(event.target.value)}>
                        <MenuItem value={false}>Not Independent</MenuItem>
                        <MenuItem value={true}>Independent</MenuItem>
                    </Select>
                    </FormControl>
                    <br></br>
                    <FormControl style={{width: 400, margin: "0 auto"}}>
                    <InputLabel id="demo-simple-select-label">Access</InputLabel>
                    <Select
                        labelId="select-access"
                        id="select-access-id"
                        value={access}
                        style={{width: 400, margin: "0 auto"}}
                        label={"Access"}
                        onChange={(event) => setAccess(event.target.value)}>
                        <MenuItem value={"released"}>Publicly Released</MenuItem>
                        <MenuItem value={"restricted"}>Access Restricted</MenuItem>
                        <MenuItem value={"unavailable"}>Unavailable</MenuItem>
                    </Select>
                    </FormControl>
                    {access === "restricted" && <TextField value={accessCode} onChange={(event) => setAccessCode(event.target.value)} id="outlined-basic" label="Access Code" variant="outlined" multiline maxRows={8} sx={{width: 400, margin: 1}} />}
                    
                    {
                        request &&
                        <><br></br><a target="_blank" href={viewableFilm}>View Film File</a>
                        <a target="_blank" href={viewableThumbnail}>View Thumbnail</a><br></br></>
                    }

                    {request &&
                    <>
                        <br></br>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                            <Button onClick={() => confirmRequest(true)} variant="contained" color="success" style={{fontSize: 15, margin: 5}}>Confirm</Button>
                            <Button onClick={() => confirmRequest(false)} variant="contained" color="success" style={{fontSize: 15, margin: 5}}>Deny All</Button>
                        </div>
                    </>
                    }
                    {!request &&
                        <Button onClick={() => setStage(2)} variant="contained" color="warning" style={{fontSize: 20, marginTop: 10}}>Submit</Button>
                    }
                </CardContent>
            </Card>}
            {stage === 2 && <Card variant="outlined" sx={{width: 500, margin: "0 auto"}}>
                <CardContent style={{display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center"}}>
                    <p style={{color: "black", fontSize: 30, marginTop: 0}}>Film ID: <strong>{selectedFilm}</strong></p>
                    {!newFilm && <p style={{color: "black", fontSize: 15, textAlign: "center", width: "70%", marginTop: -20}}>A film record with this ID already exists. You may either upload a new file or keep the current one.</p>}
                    {newFilm && <p style={{color: "black", fontSize: 15, textAlign: "center", width: "70%", marginTop: -20}}>A film record with this ID does not yet exist. Please upload your video file.</p>}
                    <Button onClick={() => importFile("video")} variant="contained" color="warning" style={{fontSize: 20, marginTop: 10}}>choose a video file</Button>
                    {!newFilm && <Button onClick={() => setStage(3)} variant="contained" color="warning" style={{fontSize: 20, marginTop: 10}}>keep current file</Button>}
                    {props.Exec &&
                    <><br></br>
                    <TextField value={filmOverride} onChange={(event) => setFilmOverride(event.target.value)} id="outlined-basic" label="File Name Override" variant="outlined" multiline maxRows={8} sx={{width: 400, margin: 1}} />
                    <Button onClick={() => {setFilmFileName(filmOverride); setStage(3)}} variant="contained" color="warning" style={{fontSize: 20, marginTop: 10}}>file name override</Button>
                    </>}
                </CardContent>
            </Card>}
            {stage === 3 && <Card variant="outlined" sx={{width: 500, margin: "0 auto"}}>
                <CardContent style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                    <p style={{color: "black", fontSize: 30, marginTop: 0}}>Film ID: <strong>{selectedFilm}</strong></p>
                    {!newFilm && <p style={{color: "black", fontSize: 15, textAlign: "center", width: "70%", marginTop: -20}}>A film record with this ID already exists. You may either upload a new thumbnail or keep the current one.</p>}
                    {newFilm && <p style={{color: "black", fontSize: 15, textAlign: "center", width: "70%", marginTop: -20}}>A film file with this ID does not yet exist. Please upload your thumbnail file.</p>}
                    <Button onClick={() => importFile("image")} variant="contained" color="warning" style={{fontSize: 20, marginTop: 10}}>choose a thumbnail file</Button>
                    {!newFilm && <Button onClick={() => setStage(4)} variant="contained" color="warning" style={{fontSize: 20, marginTop: 10}}>keep current file</Button>}
                    {props.Exec &&
                    <><br></br>
                    <TextField value={thumbOverride} onChange={(event) => setThumbOverride(event.target.value)} id="outlined-basic" label="File Name Override" variant="outlined" multiline maxRows={8} sx={{width: 400, margin: 1}} />
                    <Button onClick={() => {setThumbnailName(thumbOverride); setStage(4)}} variant="contained" color="warning" style={{fontSize: 20, marginTop: 10}}>file name override</Button>
                    </>}
                </CardContent>
            </Card>}
            {stage === 4 && <Card variant="outlined" sx={{width: 500, margin: "0 auto"}}>
                <CardContent style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                    <p style={{color: "black", fontSize: 30, marginTop: 0}}>Film ID: <strong>{selectedFilm}</strong></p>
                    {!newFilm && <p style={{color: "black", fontSize: 15, textAlign: "center", width: "70%", marginTop: -20}}>A film record with this ID already exists. You may either upload a new thumbnail or keep the current one.</p>}
                    {newFilm && <p style={{color: "black", fontSize: 15, textAlign: "center", width: "70%", marginTop: -20}}>A film file with this ID does not yet exist. Please upload your thumbnail file.</p>}
                    <Button onClick={() => importFile("document")} variant="contained" color="warning" style={{fontSize: 20, marginTop: 10}}>choose a script file</Button>
                    <Button onClick={() => {setScriptName(""); setStage(5)}} variant="contained" color="warning" style={{fontSize: 20, marginTop: 10}}>do not include script</Button>
                    {!newFilm && <Button onClick={() => setStage(5)} variant="contained" color="warning" style={{fontSize: 20, marginTop: 10}}>keep current file</Button>}
                    {props.Exec &&
                    <><br></br>
                    <TextField value={scriptOverride} onChange={(event) => setScriptOverride(event.target.value)} id="outlined-basic" label="File Name Override" variant="outlined" multiline maxRows={8} sx={{width: 400, margin: 1}} />
                    <Button onClick={() => {setScriptName(scriptOverride); setStage(5)}} variant="contained" color="warning" style={{fontSize: 20, marginTop: 10}}>file name override</Button>
                    </>}
                </CardContent>
            </Card>}
            {stage === 5 && <Card variant="outlined" sx={{width: 500, margin: "0 auto"}}>
                <CardContent style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                    <p style={{color: "black", fontSize: 30, marginTop: 0}}>Film ID: <strong>{selectedFilm}</strong></p>
                    {!newFilm && <p style={{color: "black", fontSize: 15, textAlign: "center", width: "70%", marginTop: -20}}>A film record with this ID already exists. You may either upload a new thumbnail or keep the current one.</p>}
                    {newFilm && <p style={{color: "black", fontSize: 15, textAlign: "center", width: "70%", marginTop: -20}}>A film file with this ID does not yet exist. Please upload your thumbnail file.</p>}
                    <Button onClick={() => importFile("captions")} variant="contained" color="warning" style={{fontSize: 20, marginTop: 10}}>choose a captions file</Button>
                    <Button onClick={() => {setCaptionsName(""); setStage(6)}} variant="contained" color="warning" style={{fontSize: 20, marginTop: 10}}>do not include captions</Button>
                    {!newFilm && <Button onClick={() => setStage(6)} variant="contained" color="warning" style={{fontSize: 20, marginTop: 10}}>keep current file</Button>}
                    {props.Exec &&
                    <><br></br>
                    <TextField value={captionsOverride} onChange={(event) => setCaptionsOverride(event.target.value)} id="outlined-basic" label="File Name Override" variant="outlined" multiline maxRows={8} sx={{width: 400, margin: 1}} />
                    <Button onClick={() => {setCaptionsName(captionsOverride); setStage(6)}} variant="contained" color="warning" style={{fontSize: 20, marginTop: 10}}>file name override</Button>
                    </>}
                </CardContent>
            </Card>}
            {stage === 6 && 
                <Card variant="outlined" sx={{width: 700, margin: "0 auto"}}>
                    <CardContent style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                        <p style={{fontSize: 20, marginTop: 0}}>Film ID: <strong>{selectedFilm}</strong></p>
                        <p style={{fontSize: 20, marginTop: 0}}>Title: <strong>{newTitle}</strong></p>
                        {props.Exec && <p style={{fontSize: 20, marginTop: 0}}>Semester Produced: <strong>{newSemester}</strong></p>}
                        <p style={{fontSize: 20, marginTop: 0}}>Director Name: <strong>{newDirector}</strong></p>
                        {props.Exec && <p style={{fontSize: 20, marginTop: 0}}>Authorized Editors: <strong>{newAuthorized.toString()}</strong></p>}
                        <p style={{fontSize: 20, marginTop: 0}}>Stars: <strong>{newStars}</strong></p>
                        <p style={{fontSize: 20, marginTop: 0}}>Independent: <strong>{newIndep}</strong></p>
                        <p style={{fontSize: 20, marginTop: 0, textAlign: "center"}}>Synopsis: <strong>{newSynopsis}</strong></p>
                        {props.Exec && <p style={{fontSize: 20, marginTop: 0}}>Order: <strong>{newOrder}</strong></p>}
                        <p style={{fontSize: 20, marginTop: 0}}>Access: <strong>{(access === "released" && "Publicly Released") || (access === "unavailable" && "Unavailable") || (access === "restricted" && "Access Restricted")}</strong></p>
                        {access === "restricted" && <p style={{fontSize: 20, marginTop: 0}}>Access Code: <strong>{accessCode}</strong></p>}
                        <p style={{fontSize: 20, marginTop: 0}}>Film File Updated: <strong>{filmFileUpdated ? "Yes" : "No"}</strong></p>
                        <p style={{fontSize: 20, marginTop: 0}}>Film Thumbnail Updated: <strong>{thumbnailUpdated ? "Yes" : "No"}</strong></p>
                        <p style={{fontSize: 20, marginTop: 0}}>Film Script Updated: <strong>{scriptUpdated ? "Yes" : "No"}</strong></p>
                        <p style={{fontSize: 20, marginTop: 0}}>Film Captions Updated: <strong>{captionsUpdated ? "Yes" : "No"}</strong></p>
                        <Button onClick={sendRequest} variant="contained" color="success" style={{fontSize: 20, marginTop: 10}}>{props.Exec ? "submit changes" : "submit request"}</Button>
                    </CardContent>
                </Card>
            }
            {stage === 7 &&
                <Card variant="outlined" sx={{width: 700, margin: "0 auto"}}>
                <CardContent style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                    <p style={{fontSize: 25, marginTop: 10}}><strong>{props.Exec ? "Updates Complete" : "Request Sent"}</strong></p>
                    <p style={{fontSize: 20, marginTop: 0, textAlign: "center"}}>{props.Exec ? "Your updates have been published." : "Your request has been published. The Exec Team will review your submission."}</p>
                    <Button onClick={reset} variant="outlined" color="success" style={{fontSize: 20, marginTop: 10}}>start over</Button>
                </CardContent>
            </Card>
            }

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle style={{backgroundColor: "#222222"}} id="alert-dialog-title">
                    Uploading Film
                </DialogTitle>
                <DialogContent style={{backgroundColor: "#222222"}}>
                <DialogContentText id="alert-dialog-description" style={{color: "white"}}>
                    Your film is uploading to the Buzz Studios Film Archive. Do not close this dialog until the upload has completed.
                </DialogContentText>
                </DialogContent>
                <LinearProgress variant="determinate" value={progress} />
            </Dialog>
        </div>
        <br></br><br></br>
    </>
    )
};